import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import IntlCurrencyInput from "react-intl-currency-input";
import { When, If, Then, Else } from "react-if";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import classnames from "classnames";
import qs from "qs";
import { values } from "./model";
import { occurrenceApi, hypothesisApi, feedbackParameterApi, feedbackApi } from "api";
import { getCurrentUser, isAllow } from "utils";
import { DataTypeEnum, Roles } from "model";
import { Select } from "components";
import { UtilsStore } from "stores";

const getStyles = (validate) => ({
  control: (s) => ({
    ...s,
    border: validate ? "red 1px solid" : "",
  }),
});

const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};



function checkRoleAndEmitToast(isAvaliator) {
  const lastToastDatetimeInMs = localStorage.getItem("lastToastDatetimeInMs");
  const isToastDisabled = localStorage.getItem("isToastDisabled");
  const now = Date.now();
  const timeForAnotherToastInMs = 600000;
  const itsTimeForANewToast = now - lastToastDatetimeInMs > timeForAnotherToastInMs;
  if (isAvaliator && itsTimeForANewToast && !isToastDisabled) {
    localStorage.setItem("lastToastDatetimeInMs", now);
    const onClick = () => {
      localStorage.setItem("isToastDisabled", true);
      toast.dismiss();
    }
    toast.info(
      <div className="d-flex flex-column justify-content-center" style={{ cursor: "default" }}>
        Não esqueça de preencher a avaliação sempre que possível!
        <div className="disable-toast" style={{ color: "#0069D9", cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={onClick}>
          Clique aqui para não ver mais essa notificação
        </div>
      </div>,
      { closeOnClick: false }
    );
  }
}

export default ({ avaliate, hits, plate, trackingId, classname }) => {
  const [isOpen, setIsopen] = useState(false)
  const [useful, setUseful] = useState(null);
  const [avoidedInquiry, setAvoidedInquiry] = useState();
  const [releasedClaim, setReleasedClaim] = useState(false);
  const [refusedClaim, setRefusedClaim] = useState(false);
  const [hypothesis, setHypothesis] = useState();
  const [note, setNote] = useState("");
  const [hypothesisList, setHypothesisList] = useState([]);
  const [value, setValue] = useState(0.0);
  const [hasError, setHasError] = useState(false);
  const [hasErrorServer, setHasErrorServer] = useState(false);
  const [error, setError] = useState('');
  const [inquiryValue, setInquiryValue] = useState(0);
  const [defaultInquiryValue, setDefaultInquiryValue] = useState(0)
  const [id, setId] = useState();
  const [insuranceClaim, setInsuranceClaim] = useState('');
  const isAvaliator = isAllow([
    Roles.ROLE_INSURANCE_ANALYST,
    Roles.ROLE_INSURANCE_MANAGER,
    Roles.ROLE_HOTLIST_MANAGER,
  ]);
  const toggle = () => setIsopen(v => !v);
  const onChangeValue = (_, value) => setValue(value);
  const onChangeUtility = ({ target: { value } }) => {
    const newValue = JSON.parse(value);
    setUseful(newValue);
    setAvoidedInquiry(newValue ? avoidedInquiry : '');
    if (!newValue) {
      setValue(0.0);
    }
  };
  const onChangeInquiry = ({ avoidedInquiry, releasedClaim, refusedClaim }) => {
    setAvoidedInquiry(avoidedInquiry);
    setReleasedClaim(releasedClaim);
    setRefusedClaim(refusedClaim);
  }
  const submit = async () => {
    if (
      (useful && !!hypothesis) ||
      useful === false
    ) {
      UtilsStore.setLoading(true);
      try {
        const feedback = {
          id,
          plate,
          useful,
          note,
          hits,
          insuranceClaim,
          releasedClaim: useful && releasedClaim,
          refusedClaim: useful && refusedClaim,
          notDefined: false,
          batchUuid: trackingId,
          value: useful && !releasedClaim? (value|| 0.0) : null,
          avoidedInquiry: useful && avoidedInquiry,
          inquiryValue: useful && avoidedInquiry? inquiryValue : undefined,
        };
        if (hypothesis && !!useful) {
          feedback.hypothesis = { id: hypothesis }
        }
        if (!!id) {
          await feedbackApi.update(id, feedback);
        } else {
          await feedbackApi.create(feedback);
        }
        close();
      } catch ({ response: { status } }) {
        if (status === 400) {
          setHasError(true);
          setError('Reveja as informações do formulário.')
        } else {
          setHasErrorServer(true);
          setError('Ocorreu um problema ao comunicar com o servidor por favor tente novamente mais tarde.')
        }
      } finally {
        UtilsStore.setLoading(false);
      }
    } else {
      setHasError(true);

      if (useful === null && !hypothesis) {
        setError('O campo “Hipótese” e “Pesquisa foi Útil” são obrigatórios')
      } else if (useful === null) {
        setError('O campo “Pesquisa foi Útil” é obrigatório.')
      } else {
        setError('O campo “Hipótese” é obrigatório.')
      }
    }
  };
  const close = () => {
    setUseful(null);
    setHypothesis(null);
    setNote("");
    setValue(0.0);
    setHasError(false);
    setHasErrorServer(false);
    setError('')
    setId(null);
    toggle();
  };
  const load = async () => {
    const { Company } = getCurrentUser()
    const { content: hyp } = await hypothesisApi.getAllHypotheses(Company);
    await fetchInquiryValue(Company);
    setHypothesisList(hyp.map(({ id, name }) => ({ label: name, value: id })));
  };
  
  const fetchInquiryValue = async companyUuid => {
    let inquiryValueToSet = 0;
    try {
      const feedbackParameters = await feedbackParameterApi.getByCompany(companyUuid);
      inquiryValueToSet = feedbackParameters.inquiryValue || 0;
    } finally {
      setInquiryValue(inquiryValueToSet);
    }
  }

  const loadFeedback = async () => {
    UtilsStore.setLoading(true);
    const user = getCurrentUser();
    try {
      const feedback = await feedbackApi.get(plate, trackingId);
      if (isAllow([Roles.ROLE_INSURANCE_ANALYST]) && feedback.user !== user.Email) {
        await loadInsureClaim(user.Company);
        return;
      }
      setId(feedback.id);
      setUseful(feedback.useful);
      setAvoidedInquiry(feedback.avoidedInquiry || false);
      setHypothesis(feedback.hypothesis?.id);
      setNote(feedback.note || "");
      setValue(feedback.value || 0.0);
      setInquiryValue(feedback.inquiryValue);
      setInsuranceClaim(feedback.insuranceClaim);
      setReleasedClaim(feedback.releasedClaim);
      setRefusedClaim(feedback.refusedClaim);
      UtilsStore.setLoading(false);
    } catch(e){
      await loadInsureClaim(user.Company);
    }
  };
  const loadInsureClaim = async companyUuid => {
    try {
      const filter = qs.stringify({ plate, companyUuid });
      const { content } = await occurrenceApi.fetch(filter);
      const clientData = content[0]?.clientData || [];
      const sinistro = clientData?.find(({ dataType }) => dataType === DataTypeEnum.SINISTRO.toString())?.value;
      setInsuranceClaim(sinistro);
    } finally {
      UtilsStore.setLoading(false);
    }
  }
  const loadParameters = async () => {
    UtilsStore.setLoading(true);
    try {
      const user = getCurrentUser();
      const { inquiryValue } = await feedbackParameterApi.getByCompany(user.Company);
      setDefaultInquiryValue(inquiryValue);
    } finally {
      UtilsStore.setLoading(false);
    }
  }

  useEffect(() => { isAvaliator && load(); }, []);
  useEffect(() => { checkRoleAndEmitToast(isAvaliator && avaliate); }, [avaliate]);
  useEffect(() => {
    setHasError(false);
    setError('')
  }, [useful, hypothesis, value]);
  useEffect(() => {
    isOpen && Promise.all([
      loadFeedback(),
      loadParameters()
    ])
  }, [isOpen]);

  return (
    <When condition={isAvaliator}>
      <Col xl="2" lg="3" md="4" sm="6" xs="12" className={classname}>
        <div style={{ Shadow: avaliate && "0 0.5rem 1rem grey" }}>
          <Button block onClick={toggle} disabled={!avaliate} color="info">
            Avaliar
          </Button>
        </div>
      </Col>
      <Modal {...{ toggle, isOpen }} onKeyPress={({ key }) => key === "Enter" && submit()}>
        <ModalHeader>Avaliação da Pesquisa - {plate}</ModalHeader>
        <ModalBody>
          <Alert color="danger" isOpen={hasError || hasErrorServer}>
            {error}
          </Alert>
          <Form>
            <FormGroup row>
              <Label md="3" className="font-weight-bold">
                Sinistro
              </Label>
              <Col className="d-flex align-items-center">
                <h5>{insuranceClaim || '--'}</h5>
              </Col>
            </FormGroup>
            <FormGroup row style={{ paddingTop: "2%" }}>
              <Label md="5" className="font-weight-bold">
                Pesquisa foi útil? *
              </Label>
              <Col md="3" className={classnames([{ 'text-danger': hasError && useful === null }, 'd-flex align-items-center'])}>
                <input type="radio" name="useful" value="true" onClick={onChangeUtility} className={classnames(['radioCheckbox', { 'border border-danger':  hasError && useful === null}])} disabled={UtilsStore.loading} checked={useful}/>
                &nbsp;Sim
              </Col>
              <Col md="3" className={classnames([{ 'text-danger': hasError && useful === null }, 'd-flex align-items-center'])}>
                <input type="radio" name="useful" value="false" onClick={onChangeUtility} className={classnames(['radioCheckbox', { 'border border-danger':  hasError && useful === null}])} disabled={UtilsStore.loading} checked={useful === false}/>
                &nbsp;Não
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3" for="hypothesis" className="font-weight-bold">
                Hipótese *
              </Label>
              <Col md="9">
                <Select id="hypothesis" options={hypothesisList} onChange={({ value }) => setHypothesis(value)} value={hypothesisList.find(({ value }) => value === hypothesis)} isDisabled={UtilsStore.loading || useful === false} placeholder="Selecione uma Hipótese" styles={getStyles(hasError && useful !== false && !hypothesis)}/>
              </Col>
            </FormGroup>
            <When condition={useful}>
              <FormGroup>
                <Label for="useful" className="font-weight-bold">
                  Sindicância Evitada? *
                </Label>
                <Row style={{ paddingTop: "2%" }}>
                  {
                    values().map(({ value, label, validate }, index) => (
                      <Col xs={index === 0 ? 4: index === 2? 2: ''} className={classnames([{ 'text-danger': hasError && useful && avoidedInquiry === null }])} key={value}>
                        <input type="radio" name="avoidedInquiry" onClick={() => onChangeInquiry(value)} className="radioCheckbox" disabled={UtilsStore.loading} checked={validate(avoidedInquiry, releasedClaim, refusedClaim)} style={hasError && useful && avoidedInquiry === null? { border: "red 1px solid" }: {}}/>
                        &nbsp;{label}
                      </Col>
                    ))
                  }
                </Row>
              </FormGroup>
              <If condition={avoidedInquiry}>
                <Then>
                  <FormGroup className={classnames([{ 'text-muted': releasedClaim }])}>
                    <Row>
                      <Col>
                        <Label for="amountSearch"className="font-weight-bold" >
                          Valor Economizado
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="1" className={classnames(['my-auto', 'text-pimary',{ 'd-none': releasedClaim }])}>
                        <FaPlus />
                      </Col>
                      <Col>
                        <Row className={classnames([{ 'd-none': releasedClaim }])}>
                          <Col>
                            <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChangeValue} max={9999999.99} value={value} className="form-control" disabled={UtilsStore.loading}/>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <IntlCurrencyInput currency="BRL" config={currencyConfig} max={9999999.99} value={defaultInquiryValue} className="form-control" disabled/>
                          </Col>
                        </Row>
                        <Row className={classnames([{ 'd-none': releasedClaim }])}>
                          <Col>
                            <Label for="amountSearch"className="font-weight-bold" >
                              Valor Economizado Total&nbsp;{currencyFormatter.format(defaultInquiryValue + value)}
                            </Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormGroup>
                </Then>
                <Else>
                  <Row>
                    <Col>
                      <Label for="amountSearch"className="font-weight-bold" >
                        Qual o potencial Valor Economizado?
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChangeValue} max={9999999.99} value={value} className="form-control" disabled={UtilsStore.loading}/>
                    </Col>
                  </Row>
                </Else>
              </If>
            </When>
            <FormGroup>
              <Label for="note" className="font-weight-bold">
                Observação
              </Label>
              <Input type="textarea" id="note" value={note} rows="6" style={{ resize: "none" }} disabled={UtilsStore.loading} onChange={({ target: { value } }) => value.length <= 10000 && setNote(value)}/>
              <small className="form-text text-muted float-right">
                {note.length}/10.000
              </small>
            </FormGroup>
            <br />
            <Row>
              <Col>
                <Button color="secondary" className="float-left" onClick={close} disabled={UtilsStore.loading}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button color="primary" className="float-right" onClick={submit} disabled={UtilsStore.loading}>
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </When>
  );
};
