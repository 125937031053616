const enumValue = (label, value) => Object.freeze({ label, value });

export const SUNDAY = enumValue('Domingo', 'SUNDAY');
export const MONDAY = enumValue('Segunda-feira', 'MONDAY');
export const TUESDAY = enumValue('Terça-feira', 'TUESDAY');
export const WEDNESDAY = enumValue('Quarta-feira', 'WEDNESDAY');
export const THURSDAY = enumValue('Quinta-feira', 'THURSDAY');
export const FRIDAY = enumValue('Sexta-feira', 'FRIDAY');
export const SATURDAY = enumValue('Sábado', 'SATURDAY');

export const values = () => [
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
];

export const map = fn => values().map(fn);