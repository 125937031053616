import { useLocation } from "react-router-dom";

class BreadcrumbInfo {
    constructor(title='', link='#'){
      this.title = title;
      this.link = link;
    }
}


export function useBreadCrumbLevels() {
    const location = useLocation();
    const overviewUuid = (location.pathname.match(/[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/g)||[])[0];
    const breadcrumb = {
        '/search(/\\d*)?': [new BreadcrumbInfo('Pesquisar')],
        '/dashboard': [new BreadcrumbInfo('Dashboard')],
        '/tracking/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/[A-z]{3}\\d(\\d|[A-z])\\d{2}': [new BreadcrumbInfo('Car Tracking', '/tracking'), new BreadcrumbInfo('Detalhes')],
        '/tracking': [new BreadcrumbInfo('Car Tracking')],
        '/clientes/busca': [new BreadcrumbInfo('Clientes'), new BreadcrumbInfo('Buscar')],
        '/clientes/uploads': [new BreadcrumbInfo('Clientes'), new BreadcrumbInfo('Uploads')],
        '/avaliacoes/pendentes': [new BreadcrumbInfo('Avaliações'), new BreadcrumbInfo('Pendentes')],
        '/avaliacoes/[^/]+/editar': [new BreadcrumbInfo('Avaliações', '/avaliacoes'), new BreadcrumbInfo('Editar')],
        '/avaliacoes/uploads': [new BreadcrumbInfo('Avaliações'), new BreadcrumbInfo('Realizadas', '/avaliacoes'), new BreadcrumbInfo('Uploads')],
        '/avaliacoes': [new BreadcrumbInfo('Avaliações'), new BreadcrumbInfo('Realizadas')],
        '/uploader/uploads': [new BreadcrumbInfo('Uploader'), new BreadcrumbInfo('Lotes')],
        '/uploader/qualidade': [new BreadcrumbInfo('Uploader'), new BreadcrumbInfo('Qualidade')],
        '/quali-api/overview/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/detalhes/[A-z]{3}\\d(\\d|[A-z])\\d{2}': [new BreadcrumbInfo('Quali API', `/quali-api/overview/${overviewUuid}`), new BreadcrumbInfo('Overview', `/quali-api/overview/${overviewUuid}`), new BreadcrumbInfo('Detalhes')],
        '/quali-api/overview/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})': [new BreadcrumbInfo('Quali API'), new BreadcrumbInfo('Overview')],
        '/quali-api/uploads/[A-z]{3}\\d(\\d|[A-z])\\d{2}': [new BreadcrumbInfo('Quali API', '/quali-api/uploads'), new BreadcrumbInfo('Uploads', '/quali-api/uploads'), new BreadcrumbInfo('Detalhes')],
        '/quali-api/uploads': [new BreadcrumbInfo('Quali API'), new BreadcrumbInfo('Uploads')],
        '/quali-api/qualidade': [new BreadcrumbInfo('Quali API'), new BreadcrumbInfo('Qualidade')],
        '/tracker/qualidade': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Qualidade')],
        '/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/editar': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Webhooks', '/tracker/webhooks'), new BreadcrumbInfo('Edição')],
        '/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/regras/d+/editar': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Webhooks', '/tracker/webhooks'), new BreadcrumbInfo('Regras de envio', '/tracker/webhooks/regras'), new BreadcrumbInfo('Edição')],
        '/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/regras': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Webhooks', '/tracker/webhooks'), new BreadcrumbInfo('Regras de envio')],
        '/tracker/webhooks': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Webhooks')],
        '/tracker/contatos': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Contatos')],
        '/tracker/detalhes/(\\w{3}\\d(\\d|\\w)\\d{2})': [new BreadcrumbInfo('Monitoramento', '/tracker'), new BreadcrumbInfo('Detalhes')],
        '/tracker': [new BreadcrumbInfo('Monitoramento'), new BreadcrumbInfo('Listas')],
        '/duble/suspeita/qualidade': [new BreadcrumbInfo('Veículo Dublê'), new BreadcrumbInfo('Validação de Suspeitas', '/duble/suspeita'), new BreadcrumbInfo('Qualidade')],
        '/duble/suspeita': [new BreadcrumbInfo('Veículo Dublê'), new BreadcrumbInfo('Validação de Suspeitas')],
        '/duble/qualidade': [new BreadcrumbInfo('Veículo Dublê'), new BreadcrumbInfo('Qualidade')],
        '/targetList': [new BreadcrumbInfo('Target Lists')],
        '/qualidade': [new BreadcrumbInfo('Target Lists', '/targetList'), new BreadcrumbInfo('Qualidade')],
        '/integrada': [new BreadcrumbInfo('Consulta Integrada')],
        '/dossie/\\d+': [new BreadcrumbInfo('Dossiês', '/dossie'), new BreadcrumbInfo('Placas')],
        '/dossie': [new BreadcrumbInfo('Dossiês')],
        '/cameras/novo': [new BreadcrumbInfo('Câmeras', '/cameras'), new BreadcrumbInfo('Novo')],
        '/cameras/[^/]+/editar': [new BreadcrumbInfo('Câmeras', '/cameras'), new BreadcrumbInfo('Editar')],
        '/cameras/monitor': [new BreadcrumbInfo('Câmeras', '/cameras'), new BreadcrumbInfo('Monitoramento')],
        '/cameras': [new BreadcrumbInfo('Câmeras')],
        '/fornecedores/[^/]+/editar': [new BreadcrumbInfo('Fornecedores'), new BreadcrumbInfo('Editar')],
        '/fornecedores': [new BreadcrumbInfo('Fornecedores')],
        '/empresas/novo': [new BreadcrumbInfo('Empresas', '/empresas'), new BreadcrumbInfo('Novo')],
        '/empresas/[^/]+/editar': [new BreadcrumbInfo('Empresas', '/empresas'), new BreadcrumbInfo('Editar')],
        '/empresas': [new BreadcrumbInfo('Empresas')],
        '/solicitacoes/.+': [new BreadcrumbInfo('Solicitações de Acesso', '/solicitacoes'), new BreadcrumbInfo('Aprovação')],
        '/solicitacoes': [new BreadcrumbInfo('Solicitações de Acesso')],
        '/usuarios/[^/]+/editar': [new BreadcrumbInfo('Usuários', '/usuarios'), new BreadcrumbInfo('Editar')],
        '/usuarios/uploads': [new BreadcrumbInfo('Usuários', '/usuarios'), new BreadcrumbInfo('Uploads')],
        '/usuarios/novo': [new BreadcrumbInfo('Usuários', '/usuarios'), new BreadcrumbInfo('Novo')],
        '/usuarios': [new BreadcrumbInfo('Usuários')],
    }
    return Object.entries(breadcrumb).find(([regex]) => location.pathname.match(regex))[1];
}