import * as Roles from "./Roles";

const enumValue = (description, value) =>
  Object.freeze({
    toString: () => description,
    getValue: () => [
      Roles.ROLE_USER.toString(),
      ...value.map((item) => item.toString()),
    ],
  });

const GERENTE = enumValue("Gerente", [
  Roles.ROLE_INSURANCE_MANAGER,
  Roles.ROLE_HOTLIST_MANAGER,
  Roles.ROLE_CUSTOMER_MANAGER,
]);

const GERENTE_PLUS = enumValue("Gerente +", [
  Roles.ROLE_INSURANCE_MANAGER,
  Roles.ROLE_HOTLIST_MANAGER,
  Roles.ROLE_CUSTOMER_MANAGER,
  Roles.ROLE_INSURANCE_CARTRACKING
]);

const ADMIN = enumValue("Administrador", [Roles.ROLE_ADMIN, Roles.ROLE_NOTIFICATION_DEVICE_TOKEN_UPDATE]);

const ADMIN_CAMERA = enumValue("Administrador de Câmeras", [
  Roles.ROLE_CAMERA_ADMIN,
]);

const ANALISTA = enumValue("Analista", [Roles.ROLE_ANALYST]);

const ANALISTA_SEGURADORA = enumValue("Analista da Seguradora", [
  Roles.ROLE_INSURANCE_ANALYST,
]);

const ANALISTA_SEGURADORA_PLUS = enumValue("Analista da Seguradora +", [
  Roles.ROLE_INSURANCE_ANALYST,
  Roles.ROLE_INSURANCE_CARTRACKING
]);

const CATAPLACA = enumValue("CataPlaca", [Roles.ROLE_PLATE_HUNTER]);

const GERENTE_HOTLIST = enumValue("Gerente de hotlist", [
  Roles.ROLE_HOTLIST_MANAGER,
  Roles.ROLE_CUSTOMER_MANAGER,
  Roles.ROLE_NOTIFICATION_DEVICE_TOKEN_UPDATE,
  Roles.ROLE_NOTIFICATION_TOPIC_FIND,
  Roles.ROLE_NOTIFICATION_SUBSCRIPTION
]);

const GERENTE_SEGURADORA = enumValue("Gerente da Seguradora", [
  Roles.ROLE_INSURANCE_MANAGER,
]);

const GERENTE_SEGURADORA_PLUS = enumValue("Gerente da Seguradora +", [
  Roles.ROLE_INSURANCE_MANAGER,
  Roles.ROLE_INSURANCE_CARTRACKING
]);

const OPERADOR = enumValue("Operador", [Roles.ROLE_SEARCH]);

const OPERADOR_CAMERA = enumValue("Operador de Câmeras", [
  Roles.ROLE_RESEARCHER_CAMERA
]);


const OPERADOR_QUALIDADE = enumValue("Operador de Qualidade", [
  Roles.ROLE_QUALITY,
]);

const OPERADOR_QUALIDADE_HOTLIST = enumValue("Operador de Qualidade Hotlist", [
  Roles.ROLE_QUALITY_HOTLIST
])

const RASTREADOR_CATAPLACA = enumValue("Rastreador Cataplaca", [
  Roles.ROLE_HOTLIST_VIEWER,
  Roles.ROLE_CUSTOMER_VIEWER,
  Roles.ROLE_NOTIFICATION_DEVICE_TOKEN_UPDATE,
  Roles.ROLE_NOTIFICATION_TOPIC_FIND,
  Roles.ROLE_NOTIFICATION_SUBSCRIPTION
]);

export const values = () => [
  ADMIN,
  ADMIN_CAMERA,
  ANALISTA,
  ANALISTA_SEGURADORA,
  ANALISTA_SEGURADORA_PLUS,
  CATAPLACA,
  GERENTE,
  GERENTE_PLUS,
  GERENTE_HOTLIST,
  GERENTE_SEGURADORA,
  GERENTE_SEGURADORA_PLUS,
  OPERADOR,
  OPERADOR_CAMERA,
  OPERADOR_QUALIDADE,
  OPERADOR_QUALIDADE_HOTLIST,
  RASTREADOR_CATAPLACA,
];

export const getPerfil = (roles) =>
  values()
    .filter(
      (perfil) =>
        roles.every((r) => perfil.getValue().includes(r)) &&
        perfil.getValue().length === roles.length
    )
    .toString();
