const enumValue = description => Object.freeze({ toString: () => description });

export const ROLE_ADMIN = enumValue('AUTHORIZER_ADMIN');
export const ROLE_USER = enumValue('AUTHORIZER_USER');
export const ROLE_PLATE_HUNTER = enumValue('PLATE_HUNTER');
export const ROLE_CAMERA_ADMIN = enumValue("CAMERA_ADMIN");
export const ROLE_CUSTOMER_MANAGER = enumValue("CUSTOMER_MANAGER");
export const ROLE_CUSTOMER_VIEWER = enumValue("CUSTOMER_VIEWER");
export const ROLE_QUALITY = enumValue('QUALITY');
export const ROLE_QUALITY_HOTLIST = enumValue('QUALITY_HOTLIST');
export const ROLE_ANALYST = enumValue('ANALYST');
export const ROLE_SEARCH = enumValue('RESEARCHER');
export const ROLE_RESEARCHER_CAMERA = enumValue("RESEARCHER_CAMERA");
export const ROLE_INSURANCE_ANALYST = enumValue('INSURANCE_ANALYST');
export const ROLE_INSURANCE_MANAGER = enumValue('INSURANCE_MANAGER');
export const ROLE_HOTLIST_MANAGER = enumValue("HOTLIST_MANAGER");
export const ROLE_HOTLIST_VIEWER = enumValue('HOTLIST_VIEWER');
export const ROLE_INSURANCE_CARTRACKING = enumValue('INSURANCE_CARTRACKING');
export const ROLE_NOTIFICATION_DEVICE_TOKEN_UPDATE = enumValue('NOTIFICATION_DEVICE_TOKEN_UPDATE');
export const ROLE_NOTIFICATION_TOPIC_FIND = enumValue("NOTIFICATION_TOPIC_FIND");
export const ROLE_NOTIFICATION_SUBSCRIPTION = enumValue("NOTIFICATION_SUBSCRIPTION");
export const ROLE_SYSTEM = enumValue('SYSTEM');